<template>
  <div>
    <!-- picD8D443BA-336B-4D34-A329-070CC49FADE0.jpg -->
    <img class="rounded leftimage" style="width: 200px; height: 225px;" src="@/assets/news-network/behind-the-scenes-with-the-lu-art-director-1.jpg">
    <p>
      In this edition, the LEGO Universe art director leads us from art fiction to his real hopes for the game.
    </p>
    <p>
      <b>Hi again Phillip. LEGO sets like Black Knight's Castle look very different from sets like the City, so how are you visually unifying LEGO Universe?</b>
    </p>
    <p>
      We could have designed the game so that all of the pieces had to visually fit into the world in specific ways, but I recognized early on that this might make it impossible to include virtual versions of some LEGO elements and play themes. So, I wanted to create an adaptable environment where it would be possible for Black Knight's Castle to come in contact with the City and it wouldn’t look out of place.
    </p>
    <p>
      Our art fiction is making that possible. Since very early on in the design process we've focused on the creative idea that imaginations will come to life in the game. So, if a child can imagine Black Knight's Castle and the City together—and kids do that from the research that I’ve seen—then it’s acceptable in LEGO Universe'
    </p>
    <p>
      From the art execution side, we are designing assets that can be used to unify two very different looking play themes. These can be simple things like small bushes placed between the two sets that soften up the visual transition. We will investigate many techniques to achieve visual continuity.
    </p>
    <p>
      <b>Tell us more about what’s inspiring your work on LEGO Universe.</b>
    </p>
    <p>
      Some animated movie companies seem to want to make films that both kids and adults will enjoy. I'd also like LEGO Universe to appeal to everybody.
    </p>
    <p>
      There are also certain science fiction films that feature remarkable user interface design. In my very early proof of concept movies, you’ll see a Minifig just pull open a screen and start building virtually; that was inspired by Hollywood.
    </p>
    <!-- pic7E4264E8-5F28-48F5-AB86-A673DE23719A.jpg -->
    <img class="rounded rightimage" style="width: 200px; height: 270px;" src="@/assets/news-network/behind-the-scenes-with-the-lu-art-director-2.jpg">

    <p>
      A final inspiration would be a consumer electronics company that’s turning out terrific user interfaces. I like the fact that they’re not afraid to take chances and they’re trying to be a leader; there are opportunities to do that with LEGO Universe.
    </p>
    <p>
      <b>What possibility for the game do you find most intriguing?</b>
    </p>
    <p>
      Remember how I said that I don’t really play games? Well, I would play this game if I could build in it! I’m a 3D artist—that’s what I do—so it would be exciting to me if I could take a creation and bring it to life.
    </p>
    <div style="clear:both;"></div>
  </div>
</template>
